'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var source = require('./faFileLines');
exports.definition = {
  prefix: source.prefix,
  iconName: source.iconName,
  icon: [
    source.width,
    source.height,
    source.aliases,
    source.unicode,
    source.svgPathData
  ]};

exports.faFileAlt = exports.definition;
exports.prefix = source.prefix;
exports.iconName = source.iconName;
exports.width = source.width;
exports.height = source.height;
exports.ligatures = source.aliases;
exports.unicode = source.unicode;
exports.svgPathData = source.svgPathData;
exports.aliases = source.aliases;